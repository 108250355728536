import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H4, TextSm } from '@system'
import MarketoFormCheckbox from './marketo-form-checkbox'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    display: 'block',
  },
  subtitle: {
    color: theme.palette.background.slate,
    marginBottom: '25px',
  },
  checkbox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkboxWidth: {
    display: 'flex',
    width: '50%',
    marginTop: '-13px',
  },
}))

const MarketoFormMultiSelect = (props) => {
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <H4 className={classes.title}>{props.blok.title}</H4>
      <TextSm className={classes.subtitle}>{props.blok.subtitle}</TextSm>
      <Box className={classes.checkbox}>
        {props.blok.fieldOptions &&
          props.blok.fieldOptions.length > 0 &&
          props.blok.fieldOptions[0].fieldOptions.map((fieldData, index) => {
            const formatData = {
              fieldId: fieldData,
              required: props.blok.required,
              text: fieldData,
              initialValue: props.blok.initialValue,
              checkboxIcons: props.blok.checkboxIcons,
            }
            return (
              <Box
                key={`${props.blok._uid}${index}`}
                className={classes.checkboxWidth}
              >
                <MarketoFormCheckbox
                  blok={formatData}
                  isMultiSelectCheckbox={true}
                />
              </Box>
            )
          })}
      </Box>
    </SbEditable>
  )
}

export default MarketoFormMultiSelect
