import React from 'react'
import SbEditable from 'storyblok-react'
import { Field as FormikField } from 'formik'
import { Box, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MarkdownHandler } from '@system'
import classNames from 'classnames'
import Image from './image'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: '90%',
    marginTop: '16px',
    flex: '1 0 100%',
    '& label': {
      color: ({ variant }) =>
        variant === 'dark'
          ? theme.palette.common.white
          : theme.palette.background.slate,
      alignItems: 'flex-start',
      marginLeft: '-9px',
      marginRight: '0px',
      '& span:first-child': {
        minWidth: '18px',
      },
    },
    '& p': {
      fontSize: ({ name }) =>
        name === 'optinDataCollection' ||
        name === 'optinMarketingCommunications'
          ? theme.typography.button.fontSize
          : theme.typography.body1.fontSize,
    },
  },
  errorMessage: {
    color: 'red',
  },
  position: {
    top: '-6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
const validate = (value, fieldId, isRequired) => {
  let error
  if (!value && isRequired) {
    error = 'Field Required'
  }
  return error
}
const CustomCheckbox = ({
  field,
  form: { touched, errors },
  variant,
  ...props
}) => {
  const classes = useStyles({ variant: variant, ...field })
  const error = touched[field.name] && errors[field.name] && props.required
  field.value = field.value === undefined ? props.initialValue : field.value
  const requiredIcon = (
    <Image
      blok={{
        image: `https://a.storyblok.com/f/64835/18x18/a55ad5b8f9/checkboxrequired.svg`,
      }}
    />
  )

  const checkedIcon = (
    <Image
      blok={{
        image: `https://a.storyblok.com/f/64835/x/23db8587f4/checkboxselected.svg`,
      }}
    />
  )

  const uncheckedIcon = (
    <Image
      blok={{
        image: `https://a.storyblok.com/f/64835/18x18/03faabb429/checkboxunselected.svg`,
      }}
    />
  )

  return (
    <>
      <FormControl key={props.index} className={classes.checkbox}>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'aria-label': 'primary checkbox' }}
              name={props.name}
              required={props.required}
              checked={!!field.value}
              icon={
                field.value === undefined || error
                  ? requiredIcon
                  : uncheckedIcon
              }
              checkedIcon={checkedIcon}
              className={classNames(classes.position, {
                [classes.isLegal]: props.isLegal,
              })}
              {...field}
            />
          }
          label={
            (
              <MarkdownHandler isDark={variant === 'dark'}>
                {props.placeholder}
              </MarkdownHandler>
            ) || 'Click Here'
          }
        />
      </FormControl>
      {error && (
        <Box className={classes.errorMessage}>{errors[field.name]}</Box>
      )}
    </>
  )
}

const MarketoFormCheckbox = (props) => {
  const {
    fieldId,
    fieldName,
    required,
    text,
    initialValue,
    CheckboxIcons,
    isLegal,
    _uid,
  } = props.blok

  return (
    <SbEditable content={props.blok}>
      <FormikField
        name={fieldId.trim() || 'CheckBox'}
        index={fieldId.trim() || _uid}
        required={required}
        component={CustomCheckbox}
        placeholder={fieldName || text}
        initialValue={initialValue}
        validate={(e) => validate(e, fieldId.trim(), required)}
        CheckboxIcons={CheckboxIcons}
        isLegal={isLegal}
        isMultiSelectCheckbox={props.isMultiSelectCheckbox}
        variant={props.variant}
      />
    </SbEditable>
  )
}
export default MarketoFormCheckbox
